<template>
    <main class="main-page">
    <template v-if="showHeader">
        <section class="page-section mb-3" >
            <div class="container">
                <div class="grid align-items-center">
                    <div  v-if="!isSubPage"  class="col-fixed " >
                        <Button @click="$router.go(-1)" label=""  className="p-button p-button-text " icon="pi pi-arrow-left"  />
                    </div>
                    <div  class="col " >
                        <div class=" text-2xl text-primary font-bold" >
                            Log Details
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </template>
    <section class="page-section " >
        <div class="container">
            <div class="grid ">
                <div  class="col comp-grid" >
                    <div >
                        <div style="min-height:100px">
                            <template v-if="pageReady">
                                <div class="mb-3 grid justify-content-start">
                                    <div class="col-12 md:col-12">
                                        <div class="grid align-items-center card surface-50 p-3">
                                            <div class="col-fixed  text-500 font-medium">Action: </div>
                                            <div class="col font-bold">{{ item.action }}</div>
                                        </div>
                                    </div>
                                    <div class="col-12 md:col-12">
                                        <div class="grid align-items-center card surface-50 p-3">
                                            <div class="col-fixed  text-500 font-medium">Page: </div>
                                            <div class="col font-bold">{{ item.page }}</div>
                                        </div>
                                    </div>
                                    <div class="col-12 md:col-12">
                                        <div class="grid align-items-center card surface-50 p-3">
                                            <div class="col-fixed  text-500 font-medium">Record ID: </div>
                                            <div class="col font-bold">{{ item.record_id }}</div>
                                        </div>
                                    </div>
                                    <div class="col-12 md:col-12">
                                        <div class="grid align-items-center card surface-50 p-3">
                                            <div class="col-fixed  text-500 font-medium">Old Values: </div>
                                            <div style="inline-size: 100%; overflow-wrap: break-word;" class="col font-bold">{{ item.old_values }}</div>
                                        </div>
                                    </div>
                                    <div class="col-12 md:col-12">
                                        <div class="grid align-items-center card surface-50 p-3">
                                            <div class="col-fixed  text-500 font-medium">New Values: </div>
                                            <div style="inline-size: 100%; overflow-wrap: break-word;" class="col font-bold">{{ item.new_values }}</div>
                                        </div>
                                    </div>
                                    <div class="col-12 md:col-12">
                                        <div class="grid align-items-center card surface-50 p-3">
                                            <div class="col-fixed  text-500 font-medium">User ID: </div>
                                            <div class="col font-bold">{{ item.user_id }}</div>
                                        </div>
                                    </div>
                                    <div class="col-12 md:col-12">
                                        <div class="grid align-items-center card surface-50 p-3">
                                            <div class="col-fixed  text-500 font-medium">User Agent: </div>
                                            <div class="col font-bold">{{ item.user_agent }}</div>
                                        </div>
                                    </div>
                                    <div class="col-12 md:col-12">
                                        <div class="grid align-items-center card surface-50 p-3">
                                            <div class="col-fixed  text-500 font-medium">Request URL: </div>
                                            <div class="col font-bold">{{ item.request_url }}</div>
                                        </div>
                                    </div>
                                    <div class="col-12 md:col-12">
                                        <div class="grid align-items-center card surface-50 p-3">
                                            <div class="col-fixed  text-500 font-medium">Timestamp: </div>
                                            <div class="col font-bold">{{$utils.humanDatetime( item.timestamp )}}</div>
                                        </div>
                                    </div>
                                </div>
                            </template>
                            <template v-if="loading">
                                <div class="p-3 text-center">
                                    <ProgressSpinner style="width:50px;height:50px" />
                                </div>
                            </template>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    </main>
</template>
<script setup>
	import {   toRefs, onMounted } from 'vue';
	import { utils } from '@/utils';
	import { useApp } from '@/composables/app.js';
	import { useViewPage } from '@/composables/viewpage.js';
	const props = defineProps({
		id: [String, Number],
		primaryKey: {
			type: String,
			default: 'log_id',
		},
		pageName: {
			type: String,
			default: 'audits',
		},
		routeName: {
			type: String,
			default: 'auditsview',
		},
		apiPath: {
			type: String,
			default: 'audits/view',
		},
		editButton: {
			type: Boolean,
			default: true,
		},
		deleteButton: {
			type: Boolean,
			default: true,
		},
		exportButton: {
			type: Boolean,
			default: true,
		},
		titleBeforeDelete: {
			type: String,
			default: "Delete record",
		},
		msgBeforeDelete: {
			type: String,
			default: "Are you sure you want to delete this record?",
		},
		msgAfterDelete: {
			type: String,
			default: "Record deleted successfully",
		},
		showHeader: {
			type: Boolean,
			default: true,
		},
		showFooter: {
			type: Boolean,
			default: true,
		},
		isSubPage: {
			type : Boolean,
			default : false,
		}
	});
	const app = useApp(props);
	const page = useViewPage(props); // page hook
	const {  item, loading, pageReady } = toRefs(page.state);
	const { apiUrl, currentRecord } = page.computedProps
	const  { load, deleteItem, exportPage, moveToNextRecord, moveToPreviousRecord } = page.methods;
	function getActionMenuModel(data){
		return []
	}
	onMounted(()=>{ 
		const pageTitle = "Audit Details";
		app.setPageTitle(props.routeName, pageTitle); // set browser page title
		load();
	});
</script>
<style scoped>
</style>
